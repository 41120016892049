.main.container {
    margin-top: 60px;
}

.submit-button {
    margin-top: 24px !important;
    float: right;
}

.data {
    margin-top: 15px;
}

thead tr th {
    background: #e0e0e0 !important;
}
ui.inverted.dimmer {
    background-color: rgba(255,255,255,0) !important;
}

.panel-collapse {
    display: block !important;
  }

body > .grid {
    height: 100%;
}
.column {
    max-width: 450px;
    margin-top: 300px;
}

.form {
    padding-bottom: 40px;
}

.badge-success {
    color: #fff;
    background-color: red;
    margin-right: 9px;
    margin-left: 9px;
}

.badge-secondary {
    color: #fff;
    background-color: #6c757d;
    margin-right: 9px;
    margin-left: 9px;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

